<template>
    <div v-if="visible" class="cd-overlay">
        <div class="cd-loader"></div>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
